import * as vt from 'vue-toastification';
import "vue-toastification/dist/index.css";

export default defineNuxtPlugin((nuxt) => {
    nuxt.vueApp.use(vt.default, {
        position: "top-right",
        transition: "Vue-Toastification__fade"
    });
    return {
        provide: {
            toast: vt.useToast(),
        }
    }
});