import revive_payload_client_4sVQNw7RlN from "/vercel/path1/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path1/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path1/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/vercel/path1/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path1/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/vercel/path1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path1/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path1/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_w2VlvAEcpa from "/vercel/path1/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import pwa_client_Eorgdr12jA from "/vercel/path1/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import primevue_plugin_egKpok8Auk from "/vercel/path1/.nuxt/primevue-plugin.mjs";
import plugin_client_8fz26Kfk5j from "/vercel/path1/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import script_YrMPz5ICtR from "/vercel/path1/node_modules/@nuxtjs/turnstile/dist/runtime/plugins/script.mjs";
import plugin_client_i8AMfKeYnY from "/vercel/path1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/vercel/path1/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import datePicker_y6PJpeIUqN from "/vercel/path1/plugins/datePicker.ts";
import api_GFfDXud5Cr from "/vercel/path1/plugins/api.ts";
import auth_client_4lHQFpoKNF from "/vercel/path1/plugins/auth.client.ts";
import contextMenu_UuPZyI9ofn from "/vercel/path1/plugins/contextMenu.ts";
import fontawesome_cn2c4tOOHz from "/vercel/path1/plugins/fontawesome.ts";
import primevue_client_tAe5RSqVAg from "/vercel/path1/plugins/primevue.client.ts";
import vue_toastification_client_F29MfCWaUJ from "/vercel/path1/plugins/vue-toastification.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_w2VlvAEcpa,
  pwa_client_Eorgdr12jA,
  primevue_plugin_egKpok8Auk,
  plugin_client_8fz26Kfk5j,
  script_YrMPz5ICtR,
  plugin_client_i8AMfKeYnY,
  chunk_reload_client_UciE0i6zes,
  datePicker_y6PJpeIUqN,
  api_GFfDXud5Cr,
  auth_client_4lHQFpoKNF,
  contextMenu_UuPZyI9ofn,
  fontawesome_cn2c4tOOHz,
  primevue_client_tAe5RSqVAg,
  vue_toastification_client_F29MfCWaUJ
]