import type { RouterConfig } from "@nuxt/schema";

export default <RouterConfig>{
    routes: (_routes) => {
        const { ssrContext } = useNuxtApp();

        // Define the allowed subdomains
        const allowedSubdomains = ["www", "localhost", "app", "admin", "api"];
        let isSubdomain: boolean = false;

        // Check for subdomain in the url on the server
        if (process.server && ssrContext && ssrContext.event.node.req) {
            const req = ssrContext.event.node.req;
            const subdomain = req.headers.host?.split(".")[1];
            const subsubdomain = req.headers.host?.split(".")[0];
            if (subdomain && subsubdomain && subdomain === "rezervace" && !allowedSubdomains.includes(subdomain)) {
                isSubdomain = true;
            } else {
                isSubdomain = false;
            }
        }

        // Check for subdomain in the url on the client
        if (process.client && window.location.hostname) {
            const subdomain = window.location.hostname.split(".")[1];
            const subsubdomain = window.location.hostname.split(".")[0];
            if (subdomain && subsubdomain && subdomain === "rezervace" && !allowedSubdomains.includes(subdomain)) {
                isSubdomain = true;
            } else {
                isSubdomain = false;
            }
        }
        // If subdomain is found, remove the /reservation prefix from the routes
        let newRoutes = _routes;
        if (isSubdomain) {
            newRoutes = _routes.filter(
                (route) => route.path.startsWith("/reservation")
            );
            newRoutes = newRoutes.map((route) => {
                if (route.path.startsWith("/reservation")) {
                    route.path = "/";
                }
                return route;
            });
        }
        return newRoutes;
    },
};
