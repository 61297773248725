import { library, config } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
    faGoogle,
    faApple,
    faCcMastercard,
    faCcVisa,
} from "@fortawesome/free-brands-svg-icons";
import {
    faHome,
    faCalendar,
    faUsers,
    faList,
    faMessage,
    faGears,
    faUser,
    faSearch,
    faPlus,
    faBriefcase,
    faLock,
    faPen,
    faTrash,
    faPhone,
    faEnvelope,
    faCalendarDays,
    faNoteSticky,
    faXmark,
    faCircleNotch,
    faClock,
    faHouse,
    faBars,
    faClockRotateLeft,
    faPuzzlePiece,
    faCreditCard,
    faWallet,
    faCheck,
    faKey,
    faPowerOff,
    faRotate,
    faAngleRight,
    faAngleLeft,
    faCircleXmark,
    faMoon,
    faSun,
    faBox,
    faUserTie,
    faMinus,
    faLink,
    faExclamationTriangle,
    faBell,
    faGlobe,
    faUserXmark,
    faUserClock,
    faArrowsRotate,
    faInfoCircle,
    faFilePdf,
    faUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";

library.add({
    faGoogle,
    faHome,
    faCalendar,
    faUsers,
    faList,
    faMessage,
    faGears,
    faUser,
    faSearch,
    faPlus,
    faBriefcase,
    faLock,
    faPen,
    faTrash,
    faPhone,
    faEnvelope,
    faCalendarDays,
    faNoteSticky,
    faXmark,
    faCircleNotch,
    faClock,
    faHouse,
    faBars,
    faClockRotateLeft,
    faPuzzlePiece,
    faCreditCard,
    faWallet,
    faApple,
    faCheck,
    faKey,
    faPowerOff,
    faRotate,
    faAngleRight,
    faAngleLeft,
    faCcMastercard,
    faCcVisa,
    faCircleXmark,
    faMoon,
    faSun,
    faBox,
    faUserTie,
    faMinus,
    faLink,
    faExclamationTriangle,
    faBell,
    faGlobe,
    faUserXmark,
    faUserClock,
    faArrowsRotate,
    faInfoCircle,
    faFilePdf,
    faUpRightFromSquare,
});

config.autoAddCss = false;

export default defineNuxtPlugin((nuxt) => {
    nuxt.vueApp.component("font-awesome-icon", FontAwesomeIcon);
});
