import { type Business } from "~/models/business";
import { defineStore } from "pinia";

export const useBusinessesStore = defineStore({
    id: "businesses",
    state: () => ({
        businesses: [] as Business[],
        currentBusiness: null as string | null,
    }),
    getters: {
        getBusinesses: (state) => state.businesses,
        getCurrentBusinessId: (state) => state.currentBusiness,
        getCurrentBusiness: (state) =>
            state.businesses.find((b) => b.id == state.currentBusiness),
    },
    actions: {
        setCurrentBusiness(id: string) {
            this.currentBusiness = id;
        },
        updateCurrentBusiness(updated: any) {
            this.$state.businesses = this.$state.businesses.map((b) =>
                b.id == this.$state.currentBusiness ? updated : b
            );
        },
        async loadBusinesses() {
            const { $apiGetRequest, $toast } = useNuxtApp();
            const response = await $apiGetRequest("/business/all").catch(
                (error: any) => {
                    if (process.client) {
                        if (error.response.status == 400) {
                            $toast.error(
                                "Špatné uživatelské jméno nebo heslo",
                                {
                                    timeout: 2000,
                                }
                            );
                        } else {
                            $toast.error("Chyba", {
                                timeout: 1000,
                            });
                        }
                    }
                    return;
                }
            );
            if (response != undefined && response.status == "success") {
                this.businesses = response.data;
                if (this.businesses.length > 0) {
                    this.currentBusiness = this.businesses[0].id;
                }
            }
            return;
        },
    },
    // hydrate: async (state, initState) => {

    // },
});
