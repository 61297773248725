export const lastReservationTimes = [
    {
        text: "1 hodina",
        value: 1,
    },
    {
        text: "2 hodiny",
        value: 2,
    },
    {
        text: "6 hodin",
        value: 6,
    },
    {
        text: "12 hodin",
        value: 12,
    },
    {
        text: "1 den",
        value: 24,
    },
    {
        text: "2 dny",
        value: 48,
    },
    {
        text: "1 týden",
        value: 168,
    },
];

export const firstReservationTimes = [
    {
        text: "2 týdny",
        value: 336,
    },
    {
        text: "1 měsíc",
        value: 720,
    },
    {
        text: "3 měsíce",
        value: 2160,
    },
    {
        text: "6 měsíců",
        value: 4320,
    },
    {
        text: "1 rok",
        value: 8760,
    },
];
