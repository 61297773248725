import { default as googleCallbackEhq23OBPK1Meta } from "/vercel/path1/pages/auth/googleCallback.vue?macro=true";
import { default as index90dSM5RQPpMeta } from "/vercel/path1/pages/auth/index.vue?macro=true";
import { default as loginf4f2DsNMxvMeta } from "/vercel/path1/pages/auth/login.vue?macro=true";
import { default as passwordResethlE2b4U7PLMeta } from "/vercel/path1/pages/auth/passwordReset.vue?macro=true";
import { default as calendaryco83vJuaZMeta } from "/vercel/path1/pages/calendar.vue?macro=true";
import { default as indexeaGwdTkXsLMeta } from "/vercel/path1/pages/customers/[id]/index.vue?macro=true";
import { default as indexwZqqNYl6t4Meta } from "/vercel/path1/pages/customers/index.vue?macro=true";
import { default as newnv1fIXnqZAMeta } from "/vercel/path1/pages/customers/new.vue?macro=true";
import { default as dashlnjF4rhskjMeta } from "/vercel/path1/pages/dash.vue?macro=true";
import { default as indexzhmolsBfabMeta } from "/vercel/path1/pages/index.vue?macro=true";
import { default as finishfJzYGzUL2yMeta } from "/vercel/path1/pages/onboarding/finish.vue?macro=true";
import { default as new_45business8uNkbRCOfcMeta } from "/vercel/path1/pages/onboarding/new-business.vue?macro=true";
import { default as planixiY6gzCpKMeta } from "/vercel/path1/pages/onboarding/plan.vue?macro=true";
import { default as signupBvo2zwSzhlMeta } from "/vercel/path1/pages/onboarding/signup.vue?macro=true";
import { default as verify_45emailFX4WOBXcXyMeta } from "/vercel/path1/pages/onboarding/verify-email.vue?macro=true";
import { default as _91id_93siLu779tF9Meta } from "/vercel/path1/pages/orders/detail/[id].vue?macro=true";
import { default as indexv80KOeWQoOMeta } from "/vercel/path1/pages/orders/index.vue?macro=true";
import { default as price_45listbX8gLUAQ2EMeta } from "/vercel/path1/pages/price-list.vue?macro=true";
import { default as indexfQJXkxQnLIMeta } from "/vercel/path1/pages/settings/billing/index.vue?macro=true";
import { default as payment_cancelwD5yJgXR9VMeta } from "/vercel/path1/pages/settings/billing/payment_cancel.vue?macro=true";
import { default as successG3sn5pgkpdMeta } from "/vercel/path1/pages/settings/billing/success.vue?macro=true";
import { default as businessesdymwaS2sUmMeta } from "/vercel/path1/pages/settings/businesses.vue?macro=true";
import { default as external_45calendarDdOdCuneT9Meta } from "/vercel/path1/pages/settings/external-calendar.vue?macro=true";
import { default as indexRDl0kZWQMpMeta } from "/vercel/path1/pages/settings/index.vue?macro=true";
import { default as emailuOhHI2Hr0GMeta } from "/vercel/path1/pages/settings/modules/email.vue?macro=true";
import { default as indexI2CMmEWggvMeta } from "/vercel/path1/pages/settings/modules/index.vue?macro=true";
import { default as reservations15mcr5xgqiMeta } from "/vercel/path1/pages/settings/modules/reservations.vue?macro=true";
import { default as reservationTimes6PVfqFIcIMMeta } from "/vercel/path1/pages/settings/modules/reservationTimes.ts?macro=true";
import { default as sms43xJxsfjerMeta } from "/vercel/path1/pages/settings/modules/sms.vue?macro=true";
import { default as profile6lYHa2IqkMMeta } from "/vercel/path1/pages/settings/profile.vue?macro=true";
import { default as securitygobTi49Ib1Meta } from "/vercel/path1/pages/settings/security.vue?macro=true";
export default [
  {
    name: googleCallbackEhq23OBPK1Meta?.name ?? "auth-googleCallback",
    path: googleCallbackEhq23OBPK1Meta?.path ?? "/auth/googleCallback",
    meta: googleCallbackEhq23OBPK1Meta || {},
    alias: googleCallbackEhq23OBPK1Meta?.alias || [],
    redirect: googleCallbackEhq23OBPK1Meta?.redirect,
    component: () => import("/vercel/path1/pages/auth/googleCallback.vue").then(m => m.default || m)
  },
  {
    name: index90dSM5RQPpMeta?.name ?? "auth",
    path: index90dSM5RQPpMeta?.path ?? "/auth",
    meta: index90dSM5RQPpMeta || {},
    alias: index90dSM5RQPpMeta?.alias || [],
    redirect: index90dSM5RQPpMeta?.redirect,
    component: () => import("/vercel/path1/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: loginf4f2DsNMxvMeta?.name ?? "auth-login",
    path: loginf4f2DsNMxvMeta?.path ?? "/auth/login",
    meta: loginf4f2DsNMxvMeta || {},
    alias: loginf4f2DsNMxvMeta?.alias || [],
    redirect: loginf4f2DsNMxvMeta?.redirect,
    component: () => import("/vercel/path1/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: passwordResethlE2b4U7PLMeta?.name ?? "auth-passwordReset",
    path: passwordResethlE2b4U7PLMeta?.path ?? "/auth/passwordReset",
    meta: passwordResethlE2b4U7PLMeta || {},
    alias: passwordResethlE2b4U7PLMeta?.alias || [],
    redirect: passwordResethlE2b4U7PLMeta?.redirect,
    component: () => import("/vercel/path1/pages/auth/passwordReset.vue").then(m => m.default || m)
  },
  {
    name: calendaryco83vJuaZMeta?.name ?? "calendar",
    path: calendaryco83vJuaZMeta?.path ?? "/calendar",
    meta: calendaryco83vJuaZMeta || {},
    alias: calendaryco83vJuaZMeta?.alias || [],
    redirect: calendaryco83vJuaZMeta?.redirect,
    component: () => import("/vercel/path1/pages/calendar.vue").then(m => m.default || m)
  },
  {
    name: indexeaGwdTkXsLMeta?.name ?? "customers-id",
    path: indexeaGwdTkXsLMeta?.path ?? "/customers/:id()",
    meta: indexeaGwdTkXsLMeta || {},
    alias: indexeaGwdTkXsLMeta?.alias || [],
    redirect: indexeaGwdTkXsLMeta?.redirect,
    component: () => import("/vercel/path1/pages/customers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexwZqqNYl6t4Meta?.name ?? "customers",
    path: indexwZqqNYl6t4Meta?.path ?? "/customers",
    meta: indexwZqqNYl6t4Meta || {},
    alias: indexwZqqNYl6t4Meta?.alias || [],
    redirect: indexwZqqNYl6t4Meta?.redirect,
    component: () => import("/vercel/path1/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: newnv1fIXnqZAMeta?.name ?? "customers-new",
    path: newnv1fIXnqZAMeta?.path ?? "/customers/new",
    meta: newnv1fIXnqZAMeta || {},
    alias: newnv1fIXnqZAMeta?.alias || [],
    redirect: newnv1fIXnqZAMeta?.redirect,
    component: () => import("/vercel/path1/pages/customers/new.vue").then(m => m.default || m)
  },
  {
    name: dashlnjF4rhskjMeta?.name ?? "dash",
    path: dashlnjF4rhskjMeta?.path ?? "/dash",
    meta: dashlnjF4rhskjMeta || {},
    alias: dashlnjF4rhskjMeta?.alias || [],
    redirect: dashlnjF4rhskjMeta?.redirect,
    component: () => import("/vercel/path1/pages/dash.vue").then(m => m.default || m)
  },
  {
    name: indexzhmolsBfabMeta?.name ?? "index",
    path: indexzhmolsBfabMeta?.path ?? "/",
    meta: indexzhmolsBfabMeta || {},
    alias: indexzhmolsBfabMeta?.alias || [],
    redirect: indexzhmolsBfabMeta?.redirect,
    component: () => import("/vercel/path1/pages/index.vue").then(m => m.default || m)
  },
  {
    name: finishfJzYGzUL2yMeta?.name ?? "onboarding-finish",
    path: finishfJzYGzUL2yMeta?.path ?? "/onboarding/finish",
    meta: finishfJzYGzUL2yMeta || {},
    alias: finishfJzYGzUL2yMeta?.alias || [],
    redirect: finishfJzYGzUL2yMeta?.redirect,
    component: () => import("/vercel/path1/pages/onboarding/finish.vue").then(m => m.default || m)
  },
  {
    name: new_45business8uNkbRCOfcMeta?.name ?? "onboarding-new-business",
    path: new_45business8uNkbRCOfcMeta?.path ?? "/onboarding/new-business",
    meta: new_45business8uNkbRCOfcMeta || {},
    alias: new_45business8uNkbRCOfcMeta?.alias || [],
    redirect: new_45business8uNkbRCOfcMeta?.redirect,
    component: () => import("/vercel/path1/pages/onboarding/new-business.vue").then(m => m.default || m)
  },
  {
    name: planixiY6gzCpKMeta?.name ?? "onboarding-plan",
    path: planixiY6gzCpKMeta?.path ?? "/onboarding/plan",
    meta: planixiY6gzCpKMeta || {},
    alias: planixiY6gzCpKMeta?.alias || [],
    redirect: planixiY6gzCpKMeta?.redirect,
    component: () => import("/vercel/path1/pages/onboarding/plan.vue").then(m => m.default || m)
  },
  {
    name: signupBvo2zwSzhlMeta?.name ?? "onboarding-signup",
    path: signupBvo2zwSzhlMeta?.path ?? "/onboarding/signup",
    meta: signupBvo2zwSzhlMeta || {},
    alias: signupBvo2zwSzhlMeta?.alias || [],
    redirect: signupBvo2zwSzhlMeta?.redirect,
    component: () => import("/vercel/path1/pages/onboarding/signup.vue").then(m => m.default || m)
  },
  {
    name: verify_45emailFX4WOBXcXyMeta?.name ?? "onboarding-verify-email",
    path: verify_45emailFX4WOBXcXyMeta?.path ?? "/onboarding/verify-email",
    meta: verify_45emailFX4WOBXcXyMeta || {},
    alias: verify_45emailFX4WOBXcXyMeta?.alias || [],
    redirect: verify_45emailFX4WOBXcXyMeta?.redirect,
    component: () => import("/vercel/path1/pages/onboarding/verify-email.vue").then(m => m.default || m)
  },
  {
    name: _91id_93siLu779tF9Meta?.name ?? "orders-detail-id",
    path: _91id_93siLu779tF9Meta?.path ?? "/orders/detail/:id()",
    meta: _91id_93siLu779tF9Meta || {},
    alias: _91id_93siLu779tF9Meta?.alias || [],
    redirect: _91id_93siLu779tF9Meta?.redirect,
    component: () => import("/vercel/path1/pages/orders/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: indexv80KOeWQoOMeta?.name ?? "orders",
    path: indexv80KOeWQoOMeta?.path ?? "/orders",
    meta: indexv80KOeWQoOMeta || {},
    alias: indexv80KOeWQoOMeta?.alias || [],
    redirect: indexv80KOeWQoOMeta?.redirect,
    component: () => import("/vercel/path1/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: price_45listbX8gLUAQ2EMeta?.name ?? "price-list",
    path: price_45listbX8gLUAQ2EMeta?.path ?? "/price-list",
    meta: price_45listbX8gLUAQ2EMeta || {},
    alias: price_45listbX8gLUAQ2EMeta?.alias || [],
    redirect: price_45listbX8gLUAQ2EMeta?.redirect,
    component: () => import("/vercel/path1/pages/price-list.vue").then(m => m.default || m)
  },
  {
    name: indexfQJXkxQnLIMeta?.name ?? "settings-billing",
    path: indexfQJXkxQnLIMeta?.path ?? "/settings/billing",
    meta: indexfQJXkxQnLIMeta || {},
    alias: indexfQJXkxQnLIMeta?.alias || [],
    redirect: indexfQJXkxQnLIMeta?.redirect,
    component: () => import("/vercel/path1/pages/settings/billing/index.vue").then(m => m.default || m)
  },
  {
    name: payment_cancelwD5yJgXR9VMeta?.name ?? "settings-billing-payment_cancel",
    path: payment_cancelwD5yJgXR9VMeta?.path ?? "/settings/billing/payment_cancel",
    meta: payment_cancelwD5yJgXR9VMeta || {},
    alias: payment_cancelwD5yJgXR9VMeta?.alias || [],
    redirect: payment_cancelwD5yJgXR9VMeta?.redirect,
    component: () => import("/vercel/path1/pages/settings/billing/payment_cancel.vue").then(m => m.default || m)
  },
  {
    name: successG3sn5pgkpdMeta?.name ?? "settings-billing-success",
    path: successG3sn5pgkpdMeta?.path ?? "/settings/billing/success",
    meta: successG3sn5pgkpdMeta || {},
    alias: successG3sn5pgkpdMeta?.alias || [],
    redirect: successG3sn5pgkpdMeta?.redirect,
    component: () => import("/vercel/path1/pages/settings/billing/success.vue").then(m => m.default || m)
  },
  {
    name: businessesdymwaS2sUmMeta?.name ?? "settings-businesses",
    path: businessesdymwaS2sUmMeta?.path ?? "/settings/businesses",
    meta: businessesdymwaS2sUmMeta || {},
    alias: businessesdymwaS2sUmMeta?.alias || [],
    redirect: businessesdymwaS2sUmMeta?.redirect,
    component: () => import("/vercel/path1/pages/settings/businesses.vue").then(m => m.default || m)
  },
  {
    name: external_45calendarDdOdCuneT9Meta?.name ?? "settings-external-calendar",
    path: external_45calendarDdOdCuneT9Meta?.path ?? "/settings/external-calendar",
    meta: external_45calendarDdOdCuneT9Meta || {},
    alias: external_45calendarDdOdCuneT9Meta?.alias || [],
    redirect: external_45calendarDdOdCuneT9Meta?.redirect,
    component: () => import("/vercel/path1/pages/settings/external-calendar.vue").then(m => m.default || m)
  },
  {
    name: indexRDl0kZWQMpMeta?.name ?? "settings",
    path: indexRDl0kZWQMpMeta?.path ?? "/settings",
    meta: indexRDl0kZWQMpMeta || {},
    alias: indexRDl0kZWQMpMeta?.alias || [],
    redirect: indexRDl0kZWQMpMeta?.redirect,
    component: () => import("/vercel/path1/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: emailuOhHI2Hr0GMeta?.name ?? "settings-modules-email",
    path: emailuOhHI2Hr0GMeta?.path ?? "/settings/modules/email",
    meta: emailuOhHI2Hr0GMeta || {},
    alias: emailuOhHI2Hr0GMeta?.alias || [],
    redirect: emailuOhHI2Hr0GMeta?.redirect,
    component: () => import("/vercel/path1/pages/settings/modules/email.vue").then(m => m.default || m)
  },
  {
    name: indexI2CMmEWggvMeta?.name ?? "settings-modules",
    path: indexI2CMmEWggvMeta?.path ?? "/settings/modules",
    meta: indexI2CMmEWggvMeta || {},
    alias: indexI2CMmEWggvMeta?.alias || [],
    redirect: indexI2CMmEWggvMeta?.redirect,
    component: () => import("/vercel/path1/pages/settings/modules/index.vue").then(m => m.default || m)
  },
  {
    name: reservations15mcr5xgqiMeta?.name ?? "settings-modules-reservations",
    path: reservations15mcr5xgqiMeta?.path ?? "/settings/modules/reservations",
    meta: reservations15mcr5xgqiMeta || {},
    alias: reservations15mcr5xgqiMeta?.alias || [],
    redirect: reservations15mcr5xgqiMeta?.redirect,
    component: () => import("/vercel/path1/pages/settings/modules/reservations.vue").then(m => m.default || m)
  },
  {
    name: reservationTimes6PVfqFIcIMMeta?.name ?? "settings-modules-reservationTimes",
    path: reservationTimes6PVfqFIcIMMeta?.path ?? "/settings/modules/reservationTimes",
    meta: reservationTimes6PVfqFIcIMMeta || {},
    alias: reservationTimes6PVfqFIcIMMeta?.alias || [],
    redirect: reservationTimes6PVfqFIcIMMeta?.redirect,
    component: () => import("/vercel/path1/pages/settings/modules/reservationTimes.ts").then(m => m.default || m)
  },
  {
    name: sms43xJxsfjerMeta?.name ?? "settings-modules-sms",
    path: sms43xJxsfjerMeta?.path ?? "/settings/modules/sms",
    meta: sms43xJxsfjerMeta || {},
    alias: sms43xJxsfjerMeta?.alias || [],
    redirect: sms43xJxsfjerMeta?.redirect,
    component: () => import("/vercel/path1/pages/settings/modules/sms.vue").then(m => m.default || m)
  },
  {
    name: profile6lYHa2IqkMMeta?.name ?? "settings-profile",
    path: profile6lYHa2IqkMMeta?.path ?? "/settings/profile",
    meta: profile6lYHa2IqkMMeta || {},
    alias: profile6lYHa2IqkMMeta?.alias || [],
    redirect: profile6lYHa2IqkMMeta?.redirect,
    component: () => import("/vercel/path1/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: securitygobTi49Ib1Meta?.name ?? "settings-security",
    path: securitygobTi49Ib1Meta?.path ?? "/settings/security",
    meta: securitygobTi49Ib1Meta || {},
    alias: securitygobTi49Ib1Meta?.alias || [],
    redirect: securitygobTi49Ib1Meta?.redirect,
    component: () => import("/vercel/path1/pages/settings/security.vue").then(m => m.default || m)
  }
]