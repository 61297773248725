import { ofetch } from "ofetch";
import { type User } from "~~/models/user";

const getPath = (path: string) => useRuntimeConfig().public.API_ENDPOINT + path;
export default defineNuxtRouteMiddleware(async (to, from) => {
    const userStore = useUser();
    const businesses = useBusinessesStore();
    const whitelist = [
        "/auth/login",
        "/auth/googleCallback",
        "/onboarding/signup",
        "/",
    ];
    if (process.server) return;
    if (
        whitelist.includes(to.path) ||
        to.path.startsWith("/auth/passwordReset") ||
        to.path.startsWith("/reservation/") ||
        to.path.startsWith("/auth/verify-email")
    ) {
        return;
    } else {
        if (userStore.getUser) {
            if (
                businesses.getBusinesses.length == 0 &&
                to.path != "/onboarding/new-business"
            ) {
                return navigateTo("/onboarding/new-business");
            }
            if (
                !businesses.getCurrentBusiness?.subscription &&
                !to.path.startsWith("/onboarding/")
            ) {
                return navigateTo("/onboarding/plan");
            }
            return;
        }
        let resp;
        const { data, error } = await useFetch(getPath("/user"), {
            credentials: "include",
        });
        if (error.value) return navigateTo("/auth/login");
        resp = data.value as any;
        if (resp && resp.status === "success") {
            userStore.setUser({
                email: resp.data.email,
                id: resp.data.id,
                name: resp.data.name,
                googleConnected: resp.data.googleConnected,
                phone: resp.data.phone,
                passwordChanged: resp.data.passwordChanged,
                passwordSet: resp.data.passwordSet,
                address: resp.data.address,
            } as User);
            await businesses.loadBusinesses();
            if (businesses.getBusinesses.length == 0) {
                navigateTo("/onboarding/new-business");
            }
            if (
                (businesses.getCurrentBusiness?.subscription === null ||
                    businesses.getCurrentBusiness?.subscription?.status !==
                        "active") &&
                to.path != "/onboarding/plan" &&
                !to.path.startsWith("/onboarding/")
            ) {
                return navigateTo("/onboarding/plan");
            }
            return;
        } else {
            return navigateTo("/auth/login");
        }
    }
});
