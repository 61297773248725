export default defineNuxtPlugin((nuxt) => {
    const user = useUser();
    const {$apiPostRequest} = useNuxtApp();

    const logout = async () => {
        user.logout();
        await $apiPostRequest("/auth/logout", {});
        navigateTo("/auth/login");
    };

    return {
        provide: {
            logout: logout,
        },
    };
});
