// Plugin providing predefined ofetch instance

import { useUser } from "~~/composables/user";
import { ofetch } from "ofetch";

const getPath = (path: string) => useRuntimeConfig().public.API_ENDPOINT + path;

const apiPostRequest = async (path: string, body: object) => {
    const userStore = useUser();
    let response: Response = await ofetch(getPath(path), {
        method: "POST",
        body: body,
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }).catch((e) => {
        if (
            e.response._data.error === "invalid-token" ||
            e.response._data.error === "expired-token"
        ) {
            userStore.logout();
            navigateTo("/auth/login");
            return;
        }
        throw e;
    });
    return response;
};

const apiGetRequest = async (path: string) => {
    const userStore = useUser();
    return await ofetch(getPath(path), {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }).catch((e) => {
        if (
            e.response._data.error === "invalid-token" ||
            e.response._data.error === "expired-token"
        ) {
            userStore.logout();
            navigateTo("/auth/login");
            return;
        }
        throw e;
    });
};

const apiDeleteRequest = async (path: string) => {
    const userStore = useUser();
    return await ofetch(getPath(path), {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }).catch((e) => {
        if (
            e.response._data.error === "invalid-token" ||
            e.response._data.error === "expired-token"
        ) {
            userStore.logout();
            navigateTo("/auth/login");
            return;
        }
        throw e;
    });
};

export default defineNuxtPlugin((nuxt) => {
    return {
        provide: {
            apiGetRequest: apiGetRequest,
            apiPostRequest: apiPostRequest,
            apiDeleteRequest: apiDeleteRequest,
        },
    };
});
