import { type User } from "~~/models/user";
import { defineStore } from "pinia";

export const useUser = defineStore({
    id: "user",
    state: () => ({
        user: null as User | null,
    }),
    getters: {
        getUser: (state) => state.user,
        getName: (state) => state.user?.name,
        getStreet: (state) => state.user?.address.street,
        getCity: (state) => state.user?.address.city,
        getZip: (state) => state.user?.address.zipCode,
    },
    actions: {
        setUser(user: User) {
            this.user = user;
        },
        logout() {
            this.user = null;
        },
    },

    // hydrate(state, initialState) {
    //     // TODO: Fetch new user data from server
    //     state.user = localStorage.getItem("user")
    //         ? JSON.parse(localStorage.getItem("user")!)
    //         : null;
    // },
});
